<template>
  <div>
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'TheOfflineDefaultView'
};
</script>
